import React from "react";

const Container = ({ children }) => {
  return (
    <div className="w-full flex flex-row items-center justify-center">
      <div className="w-full xl:w-[1280px] 2xl:w-[1280px]">{children}</div>
    </div>
  );
};

export default Container;
