import React from "react";
import Container from "./Container";

const Hero = () => {
  return (
    <Container>
      <div className="bg-slate-50 rounded-br-[200px] flex flex-col xl:flex-row overflow-hidden md:px-[100px]">
        <div className="pt-[40px] xl:pt-[140px] pb-[30px] xl:pb-[50px] px-[35px] xl:w-[50%]">
          <h1 className="bg-gradient-to-r from-violet-500 to-pink-500 bg-clip-text text-transparent font-bold text-xl xl:text-2xl mb-1 xl:mb-0">
            Web & Mobile
          </h1>
          <span className="text-[26px] xl:text-[44px]">Software Developer</span>
          <p className="my-2 text-md">
            Transformando ideias em experiências inesquecíveis e criando
            soluções únicas!
          </p>

          <div className="">
            <button className="px-5 py-3 bg-slate-900 text-white rounded-3xl mt-2 text-sm font-medium hover:opacity-[80%]">
              Saiba mais
            </button>

            <button className="px-5 py-3 bg-transparent text-slate-900 rounded-3xl mt-2 text-sm font-bold hover:opacity-[80%]">
              Meus projetos
            </button>
          </div>
        </div>
        <div
          className="block h-[300px] xl:h-auto xl:mt-[20px] w-full md:w-[300px] object-cover bg-no-repeat bg-top mx-[16px] xl:w-[50%]"
          style={{ backgroundImage: "url('hero.png')", backgroundSize: "90%" }}
        >
          {/* <img src="hero.png" alt="" /> */}
        </div>
      </div>
    </Container>
  );
};

export default Hero;
