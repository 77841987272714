import React, { useState } from "react";

import { Menu, X } from "react-feather";

import { motion } from "framer-motion";

const Header = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Container>
      <div className="bg-slate-50 p-4 md:p-0">
        <nav className="text-md bg-white py-4 hidden md:block shadow-sm">
          <ul className="flex flex-row justify-center items-center">
            <li>
              <a
                href="./"
                className="block px-3 py-2 text-center hover:text-gray-500"
              >
                Início
              </a>
            </li>
            <li>
              <a
                href="./"
                className="block px-3 py-2 text-center hover:text-gray-500"
              >
                Redes sociais
              </a>
            </li>
            <li>
              <a
                href="./"
                className="block px-3 py-2 text-center hover:text-gray-500"
              >
                Sobre
              </a>
            </li>
            <li>
              <a
                href="./"
                className="block px-3 py-2 text-center hover:text-gray-500"
              >
                Serviços
              </a>
            </li>
            <li>
              <a
                href="./"
                className="block px-3 py-2 text-center hover:text-gray-500"
              >
                Projetos
              </a>
            </li>
            <li className="flex flex-row justify-center items-center">
              <a
                href="./"
                className="inline-block mx-5 my-1 mb-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded-3xl px-7 py-2 font-medium text-center"
              >
                Enviar mensagem
              </a>
            </li>
          </ul>
        </nav>

        <nav className="text-md bg-white rounded-2xl md:rounded-none md:hidden py-4 shadow-sm">
          <ul className={isExpanded ? "hidden" : "flex-col"}>
            <li>
              <a
                href="/#"
                onClick={() => setIsExpanded(!isExpanded)}
                className="flex justify-items-start px-5 py-2 text-gray-600 hover:text-gray-500"
              >
                <Menu strokeWidth={1.5} />
              </a>
            </li>
          </ul>

          <ul className={isExpanded ? "flex-col" : "hidden"}>
            <li>
              <a
                href="/#"
                className="flex flex-row items-center justify-center px-5 py-2 text-center text-gray-600 hover:text-gray-500"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                <X strokeWidth={1.5} />
              </a>
            </li>
          </ul>

          <motion.ul
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{
              opacity: isExpanded ? 1 : 0,
              scale: isExpanded ? 1 : 0.8,
            }}
            transition={{ duration: 0.5 }}
            className={isExpanded ? "flex-col" : "hidden"}
          >
            <li>
              <a
                href="./"
                className="block px-5 py-2 text-center hover:text-gray-500"
              >
                Início
              </a>
            </li>
            <li>
              <a
                href="./"
                className="block px-5 py-2 text-center hover:text-gray-500"
              >
                Redes sociais
              </a>
            </li>
            <li>
              <a
                href="./"
                className="block px-5 py-2 text-center hover:text-gray-500"
              >
                Sobre
              </a>
            </li>
            <li>
              <a
                href="./"
                className="block px-5 py-2 text-center hover:text-gray-500"
              >
                Serviços
              </a>
            </li>
            <li>
              <a
                href="./"
                className="block px-5 py-2 text-center hover:text-gray-500 pb-3"
              >
                Projetos
              </a>
            </li>
            <li className="flex flex-row justify-center items-center">
              <a
                href="./"
                className="inline-block mx-5 my-1 mb-2 bg-indigo-500 hover:bg-indigo-600 text-white rounded-3xl px-7 py-2 font-medium text-center"
              >
                Enviar mensagem
              </a>
            </li>
          </motion.ul>
        </nav>
      </div>
    </Container>
  );
};

const Container = ({ children }) => {
  return (
    <div className="w-full flex flex-row items-center justify-center">
      <div className="w-full">{children}</div>
    </div>
  );
};

export default Header;
